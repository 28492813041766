/* eslint-disable no-unused-vars */
import {useState, useEffect} from "react";
import {ArticleItem} from "components/Article/ArticleItem";
import {DashboardLayout} from "components/Layout";
import {Col, Row} from "react-bootstrap";
import {SearchBox} from "components/Table";
import {useArticlePost} from "hooks/SuperAdmin/Article";
import InfiniteScroll from "react-infinite-scroll-component";

const PostListPage = () => {
	const [searchKeyword, setSearchKeyword] = useState("");
	const [dataLimit, setDataLimit] = useState(10);
	const [dataPage, setDataPage] = useState(1);
	const {data, setFilter} = useArticlePost();
	const [articles, setArticles] = useState([]);

	useEffect(() => {
		if (data?.data?.rows) {
			setArticles(prevArticles => [...prevArticles, ...data.data.rows]);
		}
	}, [data]);

	const dataWithIDSerial =
		articles?.map((item, index) => ({
			...item,
			idSerial: (dataPage - 1) * dataLimit + index + 1,
		})) || [];

	const fetchMoreData = () => {
		const nextPage = dataPage + 1;

		setDataPage(nextPage);
		setFilter({page: nextPage, limit: dataLimit, search: searchKeyword});
	};

	return (
		<DashboardLayout>
			<div className="box-white d-flex flex-column p-4">
				<div className="d-flex justify-content-between align-items-center">
					<div>
						<h5 className="fw-bold text-black mb-3">Artikel</h5>
					</div>
					<SearchBox
						className="shadow-none"
						onSearch={keyword => {
							setSearchKeyword(keyword);
							setFilter({page: 1, limit: dataLimit, search: keyword});
							setDataPage(1);
							setArticles([]);
						}}
					/>
				</div>

				<InfiniteScroll
					className="article-post-infinite-scroll"
					dataLength={dataWithIDSerial.length}
					endMessage={null}
					hasMore={data?.data?.totalPages > dataPage}
					loader={<h4>Loading more articles...</h4>}
					next={fetchMoreData}>
					<Row className="row-gap-3">
						{dataWithIDSerial.map((item, index) => (
							<Col key={index} md={6}>
								<ArticleItem item={item} />
							</Col>
						))}
					</Row>
				</InfiniteScroll>
			</div>
		</DashboardLayout>
	);
};

export {PostListPage};
